import React from "react"
import { withStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"

const useStyles = withStyles(theme => ({
  root: {
    background: "rgba(255,255,255,1)",
    display: "flex",
    flexDirection: "row",
    transition: "all 1s",
    height: "5rem",
    overflow: "hidden",
    opacity: 1
  },
  appBarContent: {
    height: "5rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    justifyItems: "center",
    userSelect: "none",
    margin: "0 auto"
  },
  rootTranparent: {
    transition: "all 1s",
    background: "linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0))",
    boxShadow: "none",
    opacity: 0
  },
  logo: {
    textAlign: "center",
    height: "5rem",
    display: "inline"
  },
  title: {
    textAlign: "center",
    paddingLeft: "1rem",
    whiteSpace: "nowrap",
    textShadow: "2px 2px 4px rgba(0,0,0,0.4)"
  },
  rightWrapper: {
    flex: 0,
    paddingRight: "1rem",
    paddingLeft: "1rem"
  },
  buttonBase: {
    width: "4rem",
    height: "4rem"
  },
  appIcon: {
    height: "2.5rem"
  },
  logoWrapper: {
    flex: 0,
    paddingLeft: "0rem",
    paddingRight: "1rem"
  },
  flex: { flex: 1 },
  badge: {
    margin: 0
  },
  link: {
    fontWeight: 600,
    textDecoration: "underline"
  },
  nested: {
    marginLeft: theme.spacing(4)
  }
}))

class Nav extends React.Component {
  state = {
    transparent: true,
    menuOpen: false,
    cartOpen: false
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
    this.handleScroll()
  }
  setTransparent = val => {
    if (this.state.transparent !== val) {
      this.setState({ transparent: val })
    }
  }
  handleScroll = () => {
    if (window.scrollY <= window.innerHeight * 0.5) {
      this.setTransparent(true)
    } else {
      this.setTransparent(false)
    }
  }
  render() {
    const { classes } = this.props
    return (
      <AppBar position="fixed" className={`${classes.root} ${this.state.transparent ? classes.rootTranparent : ""}`}>
        <div className={classes.appBarContent}>
          <img src="/img/MarcatoLogo.png" alt="Marcato" className={classes.appIcon} />
        </div>
      </AppBar>
    )
  }
}

export default useStyles(Nav)
