import React from "react"
import Nav from "./components/nav"
import "./App.css"
import Hero from "./components/hero"
import { makeStyles } from "@material-ui/core/styles"
import Fab from "@material-ui/core/Fab"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import { Parallax } from "react-parallax"

const useStyles = makeStyles(theme => ({
  logo: {
    maxWidth: "80%",
    margin: "0 auto"
  },
  center: {
    textAlign: "center"
  },
  fabWrapper: {
    marginTop: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  fab: {
    backgroundColor: "rgba(255,255,255,0.95)",
    width: "5rem",
    height: "5rem",
    margin: "0.5rem",
    cursor: "inherit",
    "& *": {
      color: "black",
      textShadow: "none"
    },
    "&:hover": {
      backgroundColor: "white",
      "& *": {
        color: "black"
      }
    }
  },
  content: {
    paddingTop: "3rem",
    paddingBottom: "3rem"
  }
}))

function App() {
  const classes = useStyles()
  return (
    <div className="App">
      <Nav />
      <Hero imageSrc="/img/bridge.jpg">
        <Container maxWidth="sm">
          <img src="/img/MarcatoLogo-inverse.png" alt="Marcato" className={classes.logo} />
        </Container>
      </Hero>
      <section>
        <Container maxWidth="md" style={{ marginTop: "-4rem" }}>
          <div className={classes.fabWrapper}>
            <Fab className={classes.fab}>Build</Fab>
            <Fab className={classes.fab}>Craft</Fab>
            <Fab className={classes.fab}>Ship</Fab>
          </div>
        </Container>
        <Container maxWidth="md" className={classes.content}>
          <Typography variant="h4" className="heading">
            What We Do
          </Typography>
          <hr />
          <Typography variant="h6" className="serif pt-1m justify text">
            At Marcato Partners, we craft and ship software. More than that, we look to offer our clients a long-term
            partnership wherein we sustainably build and operate mobile, web, IoT, and cloud solutions for the long term
            as a fully managed service. <br />
            <br />
            Whether helping founders incubate startups, working with Fortune 500 clients, or simply engaging in
            early-stage research while taking in the river view, we apply decades of combined experience to create full
            solution architectures.
            <br />
            <br />
            We've helped assemble both co-located teams and highly effective distributed teams. We've taken companies
            from sketches on a napkin, through live products and into funding, and deep into operational excellence.
          </Typography>
          <hr />
          <Typography variant="h4" className="heading pt-1">
            Ready to build something awesome together?
          </Typography>
        </Container>

        <Container maxWidth="md" style={{ zIndex: 999, bottom: "-3rem" }}>
          <div className={classes.fabWrapper}>
            <Fab className={classes.fab}>Web</Fab>
            <Fab className={classes.fab}>IoT</Fab>
            <Fab className={classes.fab}>Mobile</Fab>
          </div>
        </Container>
        <Parallax bgImage={"/img/building.jpg"} strength={500}>
          <div
            style={{
              paddingTop: "10rem",
              paddingBottom: "10rem",
              backgroundColor: "rgba(0,0,0,0.57)"
            }}
          >
            <Container maxWidth="md">
              <fieldset>
                <legend>
                  <Typography variant="h4" className="text-img ph-2 heading">
                    Contact Us
                  </Typography>
                </legend>
                <Typography variant="h6" className="serif pt-1m text-img">
                  1922 S 1st Ave, <br />
                  Anoka, MN 55303
                  <br /> info@marcatopartners.com
                </Typography>
              </fieldset>
            </Container>
          </div>
        </Parallax>
      </section>
    </div>
  )
}

export default App
